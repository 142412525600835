export const DETAILS = {
    name: " فروشگاه امیر کتونی",
    shortName: "امیر کتونی",
    footerName: "فروشگاه امیر کتونی",
    description: "",
    shortAbout: "در اینجا، شما می‌توانید بهترین و متنوع‌ترین انواع کفش و کتونی‌های وارداتی و اورجینال را پیدا کنید.ما با تمرکز بر کیفیت بالا و طراحی به روز، ما به شما تجربه خریدی استثنایی و رضایت بخش را ارائه می‌دهیم. فروشگاه امیر کتونی با سال‌ها تجربه در عرصه فروش کفش و کتونی، به عنوان یکی از مقصدهای برتر خرید شناخته می‌شود. ما بهترین برندها و مارک‌های معتبر را انتخاب کرده‌ایم تا به شما انتخاب‌های بیشتر و کیفیت بالاتری را ارائه دهیم. در فروشگاه امیر کتونی، انواع کفش‌های مردانه، زنانه و کودکانه را می‌توانید پیدا کنید. از کفش‌های رسمی و اداری تا کفش‌های ورزشی، ما محصولاتی را داریم که به سلیقه و نیازهای همه مشتریان پاسخ می‌دهد.",
    veryShortAbout: "در اینجا، شما می‌توانید بهترین و متنوع‌ترین انواع کفش و کتونی‌های وارداتی و اورجینال را پیدا کنید.ما با تمرکز بر کیفیت بالا و طراحی به روز، ما به شما تجربه خریدی استثنایی و رضایت بخش را ارائه می‌دهیم. فروشگاه امیر کتونی با سال‌ها تجربه در عرصه فروش کفش و کتونی، به عنوان یکی از مقصدهای برتر خرید شناخته می‌شود. ما بهترین برندها و مارک‌های معتبر را انتخاب کرده‌ایم تا به شما انتخاب‌های بیشتر و کیفیت بالاتری را ارائه دهیم. در فروشگاه امیر کتونی، انواع کفش‌های مردانه، زنانه و کودکانه را می‌توانید پیدا کنید. از کفش‌های رسمی و اداری تا کفش‌های ورزشی، ما محصولاتی را داریم که به سلیقه و نیازهای همه مشتریان پاسخ می‌دهد.",
    link: "https://amirkatooni.ir",
    mobile: ["09373092015"],
    phone: ["0263427107"],
    email: [],
    postalCode: [],
    address: "",
    supportDescription: "",
    workingHours: [],
    mapIframeSrc: "",
    socialMedia: [
        /*{
            title: 'واتس اپ',
            value: '09380637888',
            name: 'whatsapp',
            link: 'https://api.whatsapp.com/send/?phone=09380637888&text&app_absent=0',
            icon: <i className="lab la-whatsapp"/>,
            isPrimary: true
        },*/
        /*{
            title: 'تلگرام',
            value: '09357195494',
            name: 'telegram',
            link: 'https://t.me/09357195494',
            icon: <i className="lab la-telegram-plane"/>,
            isPrimary: true
        },*/
        /*{
            title: 'اینستاگرام',
            value: 'selenacollectionkaraj',
            name: 'instagram',
            link: "https://instagram.com/selenacollectionkaraj",
            icon: <i className="lab la-instagram"/>,
            isPrimary: true
        },*/
        /*{
                    title: 'ایتا',
                    value: '09357195494',
                    name: 'eitaa',
                    link: 'https://eitaa.com/09357195494',
                    icon: <img src={eitaaIcon} alt='ایتا'/>,
                    isPrimary: false
                }
        {
            title: 'ایمیل',
            value: 'Volvotak@gmail.com',
            name: 'email',
            link: 'mailto:Volvotak@gmail.com',
            icon: <i className="las la-envelope"></i>,
            isPrimary: true
        },
        {
            title: 'تس',
            value: '09133377432',
            name: 'phone',
            link: 'tel:989133377432',
            icon: <PhoneCall/>,
            isPrimary: true
        },
                /*{
                    title: 'آپارات',
                    value: 'Alo_aghsat',
                    name: 'aparat',
                    link: 'https://www.aparat.com/Alo_aghsat',
                    icon: <SiAparat size={25}/>,
                    isPrimary: true
                },
                {
                    title: 'تماس',
                    value: '02636634893',
                    name: 'phone',
                    link: 'tel:+982636634893',
                    icon: <PhoneCall/>,
                    isPrimary: false
                },
                {
                    title: 'سروش پلاس',
                    value: '09331074475',
                    name: 'soroushPlus',
                    link: '',
                    icon: <img src={soroushIcon} alt='سروش'/>,
                    isPrimary: false
                },
                {
                    title: 'سروش پلاس',
                    value: '09332094475',
                    name: 'soroushPlus',
                    link: '',
                    icon: <img src={soroushIcon} alt='سروش'/>,
                    isPrimary: false
                },
                {
                    title: 'سروش پلاس',
                    value: '09330324475',
                    name: 'soroushPlus',
                    link: '',
                    icon: <img src={soroushIcon} alt='سروش'/>,
                    isPrimary: false
                },
                {
                    title: 'ایتا',
                    value: '09331074475',
                    name: 'eitaa',
                    link: '',
                    icon: <img src={eitaaIcon} alt='ایتا'/>,
                    isPrimary: false
                },*/
    ],
    services: [],
    brands: [
        /*{
            name: 'Apple',
            image: <img src={appleImage} alt='apple'/>,
        },*/
    ],
    "enamadLink": "",
    "samandehiLink": "",
    "ZarinpalLink": "https://www.zarinpal.com/webservice/TrustCode"
}
